import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../layout/layout'

import WatchVideoWithPlaceholder from '../../components/watch-video-with-placeholder'
import WatchVideo from '../../components/watch-video'
import ShoppingAreas from '../../components/shopping-areas'

import Street from '../../svg/street.svg'

import ChantalMonsma from '../../static/customers/chantal-monsma.png'
import KimHoetjes from '../../static/customers/kim-hoetjes.png'

const Page = ({ data }) => (

    <Layout>

        <Helmet>
          <title>Zupr - Uw winkelgebied tijdelijk kosteloos online</title>
          <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="city street">
            <h1>Uw winkelgebied tijdelijk kosteloos online</h1>
            <WatchVideo id="4S_tWVxrAzQ">
                Bekijk video
            </WatchVideo>
            <a className="btn btn-demo" rel="noopener noreferrer" target="_blank" href="https://www.zupr-cdn.services/static/downloads/persbericht_zupr_biedt_lokale_winkeliers_uitweg_uit_lockdown_18_dec_2020.pdf">Bekijk persbericht</a>
            <div className="background"><Street /></div>
        </section>

        <section className="text-center divider">
            <div className="inner">
                <div className="text">
                    <h2>Onze oplossing</h2>
                    <p>Het gedwongen sluiten van alle niet-essentiële winkels is een harde klap voor een sector die het al moeilijk had. Bij Zupr helpen we winkeliers en winkelgebieden in verschillende steden om met een collectief online platform consumenten snel en simpel producten online te laten zoeken en vinden. HIermee kunnen winkeliers toch online hun klanten van dienst zijn. Benieuwd hoe deze oplossing eruit ziet?</p>
                    <a target="_blank" rel="noopener noreferrer" className="btn btn-demo" href="https://www.warenhuisgroningen.nl/">Bekijk Warenhuis Groningen</a>
                </div>
            </div>
        </section>

        <section className="text-center">
            <div className="inner">
                <div className="text">
                    <h2>Kosteloos onze basisversie</h2>
                    <p>Gedurende de lockdown bieden we kosteloos de basisversie van ons product aan voor winkelgebieden en haar winkeliers die nu geen online oplossing hebben om vindbaar te zijn. Binnen enkele minuten kunnen wij een online omgeving klaar zetten zodat uw retailers direct online gevonden worden. Hiermee beschikt u snel over dezelfde functionaliteiten zoals <a href="https://www.warenhuisgroningen.nl/" target="_blank" rel="noopener noreferrer" >Warenhuis Groningen</a>, maar zijn de lay-out en domeinnaam nog niet gepersonaliseerd. Dit kan op een later tijdstip uiteraard aangepast worden naar uw wensen.</p>
                    <a className="btn btn-default brand" href="/nl/tarieven/" rel="noopener noreferrer">Bekijk onze tarieven</a>
                </div>
            </div>
        </section>

        <section className="sales-boost">
            <div className="inner">
                <h3>Heeft u interesse of vragen?</h3>
                <p>Neem dan contact met ons op via onderstaande gegevens:</p>
                <p>
                    <a href="mailto:info@zupr.nl?subject=Retailplatform" className="email">info@zupr.nl</a>
                    <a href="tel:+31854011306" className="telephone">+31 (0)85 40 11 306</a>
                </p>
            </div>
        </section>

        <section className="text-right grey">
            <div className="inner">
                <div className="text">
                    <h2>Ervaringen van winkeliers</h2>
                    <p>Benieuwd hoe winkelgebieden en winkeliers de oplossing van Zupr gebruiken? Zij deelden hun ervaringen in de video hiernaast!</p>
                </div>
                <div className="image">
                    <WatchVideoWithPlaceholder id="t-76qDy2Y1w" image="zoom-with-zupr.png">
                    </WatchVideoWithPlaceholder>
                </div>
            </div>
        </section>

        <section className="text-center quote divider">
            <div className="inner">
                <div className="text">
                    <p>“We hebben bewust gekozen voor Zupr omdat zowel food als non-food winkels een plek hebben op het platform. Daarmee bieden we de consument een compleet aanbod.”</p>
                    <div className="client">
                        <img width="64px" height="64px" src={ChantalMonsma} alt="Chantal Monsma" title="Chantal Monsma" />
                        <span><strong>Chantal Monsma</strong>, Centrummanager - <a href="https://degijsbrechtwinkelen.nl/" target="_blank" rel="noopener noreferrer" >degijsbrechtwinkelen.nl</a>, Hilversum</span>
                    </div>
                </div>
            </div>
        </section>

        <section className="text-center divider">
            <div className="inner">
                <div className="text">
                    <h2>Snelle oplossing</h2>
                    <p>Onze krachtige oplossing kan in deze lockdown periode retailers goed ondersteunen. Functies als Click&Collect en bezorgen (door de winkelier zelf of een lokale fietskoeriersdienst) behoren tot de mogelijkheden waarmee je in een paar minuten de voordelen van een webshop realiseert en verkoop mogelijk blijft.</p>
                    <p>Het bieden van een collectief platform als winkelgebied is daarbij de sleutel naar het succes: door samen te werken creëer je een aantrekkelijk geheel voor de consument. Binnen slechts enkele minuten uw eigen lokale bol.com, maar dan zonder commissies op verkopen. Bij Zupr komen de opbrengsten van verkoop toe aan de partij die er het hardst voor werkt: de retailer.</p>
                    <p>Op dit moment mogen de meeste winkeliers enkel producten verkopen als deze worden thuisbezorgd. Niet elke winkelier kan een eigen bezorgdienst opzetten, daarom werken wij samen met lokale fietskoeriers. Deze partijen kunnen eenvoudig gekoppeld worden aan ons platform waardoor elke winkelier de mogelijkheid heeft om lokaal te bezorgen.</p>
                </div>
            </div>
            <a name="areas"></a>
        </section>

        <section className="text-center quote divider">
            <div className="inner">
                <div className="text">
                    <p>“De koppeling met ons back-office systeem is ideaal. Daardoor tonen we op het platform altijd de juiste beschikbaarheid en prijs. En de consument weet ons goed te vinden: eind 2020 noteerden we onze 1000e bestelling via www.warenhuisgroningen.nl"</p>
                    <div className="client">
                    <img width="64px" height="64px" src={KimHoetjes} alt="Kim Hoetjes" title="Kim Hoetjes" />
                        <span><strong>Kim Hoetjes</strong>, Eigenaresse - <a href="https://warenhuis.groningen.nl/location/L1636279375340297" target="_blank" rel="noreferrer">WirWar Spellen</a>, Groningen</span>
                    </div>
                </div>
            </div>
        </section>

        <section className="text-center zupr-shopping-areas divider">
            <div className="inner">
                <div className="text">
                    <h2>Shopping areas van Zupr</h2>
                    <ShoppingAreas />
                </div>
            </div>
        </section>

    </Layout>
)

export default Page
